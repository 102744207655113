import { DOCUMENT, ViewportScroller, isPlatformBrowser } from '@angular/common';
import {
  ApplicationRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { delay, filter, first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';

import { RouteChangeService } from './shared/services/route-change.service';
import { Router, Scroll } from '@angular/router';
import { AuthService } from './shared';

@Component({
  selector: 'jr-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private snackBar: MatSnackBar,
    @Inject(RouteChangeService) private routeChangeService: RouteChangeService,
    private router: Router,
    viewportScroller: ViewportScroller,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .pipe(delay(1000)) //TODO: Review if Angular 16 has a fix for this workaround
      .subscribe((e) => {
        if (e instanceof Scroll) {
          if (e.position) {
            // backward navigation
            viewportScroller.scrollToPosition(e.position);
          } else if (e.anchor) {
            // anchor navigation
            viewportScroller.scrollToAnchor(e.anchor);
          } else {
            // forward navigation
            viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }

  ngOnInit(): void {
    // Setup analytics
    this.analytics();
    this.checkVersion();
  }

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('document:wheel')
  detectActivity() {
    this.authService.userActivity$.next();
  }

  analytics() {
    if (isPlatformBrowser(this.platformId)) {
      if (
        window.location.hostname === 'www.jobring.ai' ||
        window.location.hostname === 'jobring.ai'
      ) {
        this.setupHotJarScript();
        this.setupGoogleTagScript();
      }
    }
  }

  private setupHotJarScript() {
    const script = this.renderer.createElement('script');
    script.text = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3078254,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    this.renderer.insertBefore(
      this.document.head,
      script,
      this.document.head.children.item(0)
    );
  }

  private setupGoogleTagScript(): void {
    let script = this.renderer.createElement('script');
    script.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-MGFLXERCN9');`;
    this.renderer.insertBefore(
      this.document.head,
      script,
      this.document.head.children.item(0)
    );

    script = this.renderer.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-MGFLXERCN9';
    this.renderer.insertBefore(
      this.document.head,
      script,
      this.document.head.children.item(0)
    );
  }

  private checkVersion() {
    if (isPlatformBrowser(this.platformId)) {
      const appIsStable$ = this.appRef.isStable.pipe(
        first((isStable) => isStable === true)
      );

      const everySixHours$ = interval(1 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
      everySixHoursOnceAppIsStable$.subscribe(() =>
        this.updates.checkForUpdate()
      );

      this.updates.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_DETECTED') {
          navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
              for (const registration of registrations) {
                console.log('ServiceWorker unregistered: ', registration);

                registration.unregister();
              }
            });
        }
        if (event.type === 'VERSION_READY') {
          // Auto-update the version without displaying a snackBar pop-up
          this.updates.activateUpdate().then(() => this.document.location.reload());
        }
      });
    }
  }
}
