import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';
import { TokenGuard } from './shared/guards/token.guard';

const routes: Routes = [
  // PUBLIC ROUTES //
  {
    path: '',
    loadChildren: () =>
      import('./containers/public/routes').then((m) => m.ROUTES),
  },

  // AUTHENTICATED PRIVATE ROUTES //
  {
    path: 'secure',
    canActivate: [TokenGuard, AuthGuard],
    canActivateChild: [AuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./containers/private/routes').then((m) => m.ROUTES),
  },

  // ERRORS //
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      errorHandler: (e) => {
        console.error(e);
        throw e;
      },
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
