/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { DialogService } from './dialog.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.isAccessTokenExpired()) {
      if (this.authService.isRefreshTokenExpired()) {
        this.dialogService.showAlert(
          'Session Expired!',
          'You have been logged out.',
          undefined,
          false
        );
        this.authService.logout();
      } else {
        this.authService.refreshAccessToken();
      }
    }

    if (isPlatformBrowser(this.platformId)) {
      const { user } = JSON.parse(localStorage.getItem('auth') ?? '{}');
      if (!req.url.includes('s3.amazonaws.com')) {
        const newHeaders = req.headers.append(
          'Authorization',
          `Bearer ${user?.token}`
        );
        req = req.clone({ headers: newHeaders });
      }
      //If the user gets a 403 error catch then logout
      return next.handle(req).pipe(
        catchError((err) => {
          if ([403].includes(err.status)) {
            this.dialogService.showAlert(
              'Session Expired!',
              'You have been logged out.',
              undefined,
              false
            );
            this.authService.logout();
          }
          const error = err.error?.message || err.statusText;
          console.error(err);
          return throwError(() => error);
        })
      );
    }

    return next.handle(req).pipe(
      catchError((err) => {
        if ([403].includes(err.status)) {
          this.dialogService.showAlert(
            'Session Expired!',
            'You have been logged out.',
            undefined,
            false
          );
          this.authService.logout();
        }
        const error = err.error?.message || err.statusText;
        console.error(err);
        return throwError(() => error);
      })
    );
  }
}
