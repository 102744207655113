import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import {
  IReferralAttribution,
  ReferralType,
  EventTypes,
} from '@jr/types';
import { environment } from 'apps/web/src/environments/environment';
import { filter } from 'rxjs';
import { ADMIN_SERVICE_PATH } from '../constants';
import { JobService } from './jobs.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RouteChangeService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private jobService: JobService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes('/job')) {
            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem('persons_pg', event.url);
            }
            
          }
          // Keeps track of last page unauthenticated user visited
          // These pages don't have more than 2 parts to their path
          if (isPlatformBrowser(this.platformId)) {
            if (location.pathname.split('/').length <= 2) {
              localStorage.setItem('url', event.url);
            } 
          }
          
        }

        const reqObj = {
          eventType: EventTypes.PAGE_VISIT,
          path: event.urlAfterRedirects,
        };

        let isLogInRoute = false;
        if (
          event.url.includes('signin/magic-link') ||
          event.url.includes('signup')
        ) {
          isLogInRoute = true;
        }

        if (isPlatformBrowser(this.platformId)) {
          if (isLogInRoute && localStorage.getItem('persons_pg')) {
            reqObj['path'] = localStorage.getItem('persons_pg');
          }
        }

        if (isPlatformBrowser(this.platformId)) {
          this.logEvent(reqObj).subscribe((res) => {
            if (res.jobId) {
              const referralAttribution: Partial<IReferralAttribution> = {
                userId: res.userId,
                jobId: res.jobId,
                referrerId: res.referrerId,
                referralType: res.referralType,
              };
  
              this.jobService
                .createReferralAttribution(referralAttribution)
                .subscribe();
            }
          });
        }
      });
  }

  logEvent(data: { eventType: string }) {
    const url = `${environment.API_ROOT}${ADMIN_SERVICE_PATH}/event`;
    return this.http.post<{
      msg: string;
      userId?: string;
      jobId?: string;
      referrerId?: string;
      referralType?: ReferralType;
    }>(url, data, {
      withCredentials: true,
    });
  }
}
